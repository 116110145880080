import { Message } from './../models/message.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from './../Services/project.service';
import { Project } from './../models/Project.model';
import { Subscription, Subject } from 'rxjs';
import { UserService } from './../Services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireFunctions } from "@angular/fire/functions";
import 'materialize-css';
import {
  faAngleUp,
  faArchive,
  faBriefcase,
  faCoffee,
  faChess,
  faDatabase, faEnvelope, faFutbol, faGamepad,
  faGraduationCap,
  faHeart,
  faLaptopCode,
  faLink,
  faMedal, faMusic, faPlaneDeparture,
  faPlus,
  faRss,
  faSlidersH,
  faTag, faUser, faUsers, faVideo
} from '@fortawesome/free-solid-svg-icons';
import { User } from '../models/User.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  // fontawsone
  faCoffee = faCoffee;
  faUser = faUser;
  faDashboard = faDatabase;
  faGraduationCap = faGraduationCap;
  faSliders = faSlidersH;
  faArchive = faArchive;
  faHeart = faHeart;
  faEnvelope = faEnvelope;
  faReseau = faTag;
  faRss = faRss;
  faLinkedin = faLink;
  faGoogle = faPlus;
  faTwitter = faMedal;
  faExperience = faBriefcase;
  faProject = faLaptopCode;
  faSocietyGames = faChess;
  faGaming = faGamepad;
  faFootball = faFutbol;
  faTraveling = faPlaneDeparture;
  faMusic = faMusic;
  faMovie = faVideo;
  faRecommandations = faUsers;
  faAngleUp = faAngleUp;
  faContact = faEnvelope;

  // form
  contactForm: FormGroup;

  //database
  user: User;
  projects: Project[];
  userSubscription: Subscription;
  projectSubscription: Subscription;
  skillsSubscription: Subscription;
  employementSubscription: Subscription;
  studiesSubscription: Subscription;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private fun: AngularFireFunctions
  ) {
  }

  ngOnInit(): void {

    // loading user
    this.userSubscription = this.userService.user$.subscribe((me: User) => {
      this.user = me;
    });
    this.userService.loadUser();


    // loaging projects
    this.projectSubscription = this.projectService.project$.subscribe(
      projects => {
        this.projects = projects;
      }
    )
    this.projectService.getProjects();

    // init form
    this.initFrom();
  }

  initFrom() {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required && Validators.minLength(5)]],
      content: ['', [Validators.required && Validators.minLength(40)]],
      senderEmail: ['', [Validators.required && Validators.email]],
      senderName: ['', [Validators.required && Validators.minLength(4)]]
    })
  }

  onSubmit() {
    const dateNow = new Date(Date.now());
    const message = new Message(
      undefined,
      this.contactForm.get('subject').value,
      this.contactForm.get('content').value,
      this.contactForm.get('senderName').value,
      this.contactForm.get('senderEmail').value,
      dateNow
    );

    let msgObj = Object.assign({}, message);
    delete msgObj.id;

    // storing in firestore
    this.afs.collection('messages').add(msgObj).then(_ => {
      console.log('message sent successfully !');
      // console.log(_);
    }).catch(e => {
      console.log('Error : ', e);
    });
  }

  sendMail(msg: Message){
    const callable = this.fun.httpsCallable('contactMail');
    const msgObj = Object.assign({}, msg);
    callable(msgObj).subscribe();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    // this.projectSubscription.unsubscribe();
    // this.skillsSubscription.unsubscribe();
    // this.employementSubscription.unsubscribe();
    // this.studiesSubscription.unsubscribe();
  }

}
