<app-navbar></app-navbar>
<div class="container">
  <h2>Ajouter un Projet</h2>
<div class="row">

  <form [formGroup]="educationForm" (ngSubmit)="onSaveProgram()" class="col s12">

  <div class="row">
    <div class="input-field col s6">
      <input placeholder="" id="title" type="text" class="validate" formControlName="title">
      <label for="title">Formation title</label>
    </div>
    <div class="input-field col s6">
        <input id="subtitle" type="text" class="validate" formControlName="subtitle">
        <label for="subtilte">SubTitle</label>
      </div>
    <div class="input-field col s6">
      <input id="school" type="text" class="validate" formControlName="school">
      <label for="school">School</label>
    </div>
    <div class="input-field col s6">
      <input id="description" type="text" class="validate" formControlName="description">
      <label for="description">Description</label>
    </div>
    <div class="input-field col s6">
      <input type="date" id="start" class="datepicker" formControlName="start">
      <label for="start">Start</label>
    </div>
    <div class="input-field col s6">
        <input type="date" id="end" class="datepicker" formControlName="end">
        <label for="end">End</label>
      </div>
  </div>
  <div class="row">
    <div class="input-field col s12">
      <input value="" id="description" type="text" class="validate" formControlName="resume">
      <label for="description">Description</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s12">
      <input id="graduated" type="radio" class="validate" formControlName="graduated">
      <label for="graduated">Graduated ?</label>
    </div>
  </div>
  <div class="row">
    <button class="btn"  type="submit">
      Submit
      <i class="material-icons right">send</i>
    </button>
  </div>
</form>
</div>
</div>
