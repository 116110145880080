import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../Services/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signInForm: FormGroup;
  titleAlert: string = 'Champs requis !';
  serverErreur: string = '';

  constructor(private formBuilder: FormBuilder,
              private auth: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.createForm();
    this.setChangeValidate();
  }

  createForm() {
    this.signInForm = this.formBuilder.group({
      'email': ['', [Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'password': ['', [Validators.required, this.checkPassword]],
      'validate': ''
    });
  }

  setChangeValidate() {
    this.signInForm.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate === '1') {
          this.signInForm.get('email').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = 'You need to specify at least 3 characters';
        } else {
          this.signInForm.get('email').setValidators(Validators.required);
        }
        this.signInForm.get('email').updateValueAndValidity();
      }
    )
  }



  onSubmit() {
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;
    this.auth.signIn(email, password)
      .then(() => {
          this.router.navigate(['/admin', 'me']);
        }
      ).catch(
      (error) => {
        this.serverErreur = error.message;
      }
    );
  }

  get email() {
    return this.signInForm.get('email') as FormControl;
  }
  getErrorPassword() {
    return this.signInForm.get('password').hasError('required') ? 'Champs requis !': '';
    return this.signInForm.get('password').hasError('requirements') ? 'Au moinns une Lettre Maj et Une chiffre)': '';
  }
  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }
}
