export class Message {
    constructor(
        public id: string,
        public subject: string,
        public content: string,
        public senderName: string,
        public senderEmail: string,
        public createdAt?: Date
    ) { }


}