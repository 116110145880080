import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Education } from 'src/app/models/Education.model';
import { EducationService } from 'src/app/Services/education.service';

@Component({
  selector: 'app-edit-education',
  templateUrl: './edit-education.component.html',
  styleUrls: ['./edit-education.component.css']
})
export class EditEducationComponent implements OnInit {

  educationForm: FormGroup;
  fileIsUploading = false;
  fileUrl: string;
  fileUploaded = false;

  constructor(
    private educService: EducationService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.educationForm = this.formBuilder.group({
      title: ['', Validators.required],
      subtitle: '',
      school: [null, Validators.required],
      debut: [null, Validators.required],
      end: ['', Validators.required],
      description: ['', Validators.required],
      graduated: [true]
    });
  }

  onSaveProgram() {
    const title = this.educationForm.get('title').value;
    const author = this.educationForm.get('author').value;
    const technology = this.educationForm.get('technology').value;
    const resume = this.educationForm.get('resume').value;
    const lastUpdate = this.educationForm.get('lastUpdate').value;
    const picture = this.educationForm.get('picture').value;

    console.log(title);

    const newProgram = {...this.educationForm.value as Education}
    this.educService.createNewProgram(newProgram);
    this.router.navigate(['/admin', 'education']);
  }

  /*onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.projectService.uploadFile(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }*/

  /*detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }*/

}
