import { Component, OnInit, OnDestroy } from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {EditProjectComponent} from './edit-project/edit-project.component';
import {Project} from '../../models/Project.model';
import {Subscription} from 'rxjs';
import {ProjectService} from '../../Services/project.service';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects: Project[];
  dialogData: Project;
  projectsSubscription: Subscription;
  faAddProject = faPlus;

  constructor(private projectService: ProjectService,
              public dialog: MatDialog){
  }

  ngOnInit(): void {
    this.projectsSubscription = this.projectService.project$.subscribe(
      (projects: Project[]) => {
        this.projects = projects;
      }
    );
    this.projectService.getProjects();
    this.projectService.emitProjects();
  }

  public onEdit(id: number) {
    const editProject = this.projects[id];
    this.openDialog(editProject)
  }

  openDialog(project: Project): void {
    const dialogRef = this.dialog.open(EditProjectComponent, {
      width: '900px',
      data: project
    });

    dialogRef.afterClosed().subscribe(data => {
      this.dialogData = data;
    });
  }

  public onDelete(project: Project) {
    this.projectService.removeProject(project);
  }

  onViewProject(id: number){
    /*this.router.navigate()*/
  }

  public onAdd() {
    let newProject: Project;
    if (!this.dialogData) {
       newProject = new Project(
        undefined,
        '',
        [],
        [],
        '',
        null,
        0);
    } else {
      newProject = this.dialogData;
    }
    this.openDialog(newProject);
  }

  ngOnDestroy(){
    this.projectsSubscription.unsubscribe();
  }
}
