import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../Services/user.service';
import { MatInputModule } from '@angular/material/input';
import { User } from '../../models/User.model';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/operator/map'
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.css']
})
export class MeComponent implements OnInit, OnDestroy {

  userForm: FormGroup;
  active = 'inactive';
  me: User;
  userSub: Subscription;


  constructor(
    private userService: UserService,
    private fb: FormBuilder
  ) { }


  ngOnInit(): void {
    this.active = 'active';
    this.userSub = this.userService.user$.subscribe(me => {
      this.me = me;
      console.log(this.me)
      if(this.me) {
        this.initForm();
      }
    });
    this.userService.loadUser();
  }


  initForm() {
    if (this.me) {
      this.userForm = this.fb.group({
        firstName: [this.me.firstName, Validators.required],
        lastName: [this.me.lastName, Validators.required],
        age: this.me.age,
        jobTitle: [this.me.jobTitle, Validators.required],
        company: [this.me.company, Validators.required],
        description: [this.me.description, Validators.required],
        email: [this.me.email, Validators.required],
        phoneNumber: this.me.phoneNumber,
        city: [this.me.city, Validators.required],
        country: [this.me.country, Validators.required],
        address: this.me.address,
        gitRepo: this.me.gitRepo,
        twitter: this.me.twitter,
        linkedin: this.me.linkedin,
        googleplus: this.me.googleplus,
        zip: this.me.zip,
        picture: this.me.picture
      });
    }
  }

  onSubmit() {
    const formValue = {...this.userForm.value as User};
    this.me = Object.assign(this.me, formValue)
    console.log(this.me)
    this.userService.updateUser(this.me);
    console.log(this.me);
  }

  ngOnDestroy(): void {
    this.active = 'inactive';
    this.userSub.unsubscribe();
  }

  // will be used later in the project
  onAddSomething() {
    const newThing = this.fb.control('', Validators.required);
  }



}
