<div class="container">
  <h2 *ngIf="editMode" >Editer un projet</h2>
  <h2 *ngIf="!editMode" >Ajouter un projet</h2>
  <div class="row">
    <!-- CREATE MODE -->
    <form *ngIf="!editMode"  [formGroup]="projectForm" (ngSubmit)="onSaveProject()" class="col s12">
      <div class="row">
        <div class="input-field col s6">
          <input placeholder="" id="title" type="text" class="validate" formControlName="title">
          <label for="title">Project title</label>
        </div>

        <div class="input-field col s6">
          <input type="date" id="lastUpdate" class="datepicker" formControlName="lastUpdate">
          <label for="lastUpdate">Dernière MAJ</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="author" type="text" class="validate" formControlName="author">
          <label for="author">Developpeur(s)</label>
        </div>
        <div class="input-field col s6">
          <input id="technology" type="text" class="validate" formControlName="technology">
          <label for="technology">Technologies</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input value="" id="resume" type="text" class="validate" formControlName="resume">
          <label for="resume">Description</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="gitUrl" type="text" class="validate" formControlName="gitUrl">
          <label for="gitUrl">Git Url</label>
        </div>
        <div class="input-field col s6">
          <input id="deployedUrl" type="text" class="validate" formControlName="deployedUrl">
          <label for="deployedUrl">Delpoyed Url</label>
        </div>
        <!-- <div>
          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select id="status" class="validate" [formControl]='0'>
              <mat-option value=1>Livré</mat-option>
              <mat-option value=2>En développement</mat-option>
              <mat-option value=0>Choisissez une valeur</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
      <div class="row">
          <div class="input-field col s6"></div>
          <input id="picture" type="text" class="validate" formControlName="picture">
          <label for="picture">Url photo</label>

      </div>
      <div class="row">
        <div class="input-field col s4">
          <button class="btn" type="submit">
            Valider
            <i class="material-icons right">send</i>
          </button>
        </div>
      </div>
    </form>

    <!-- EDIT MODE -->
    <form *ngIf="editMode" [formGroup]="projectForm" (ngSubmit)="onUpdateProject()" class="col s12">
      <div class="row">
        <div class="input-field col s6">
          <input placeholder="" id="title" type="text" class="validate" formControlName="title">
          <label for="title">Project title</label>
        </div>

        <div class="input-field col s6">
          <input type="date" id="lastUpdate" class="datepicker" formControlName="lastUpdate">
          <label for="lastUpdate">Dernière MAJ</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="author" type="text" class="validate" formControlName="author">
          <label for="author">Developpeur(s)</label>
        </div>
        <div class="input-field col s6">
          <input id="technology" type="text" class="validate" formControlName="technology">
          <label for="technology">Technologies</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input value="" id="resume" type="text" class="validate" formControlName="resume">
          <label for="resume">Description</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="gitUrl" type="text" class="validate" formControlName="gitUrl">
          <label for="gitUrl">Git Url</label>
        </div>
        <div class="input-field col s6">
          <input id="deployedUrl" type="text" class="validate" formControlName="deployedUrl">
          <label for="deployedUrl">Delpoyed Url</label>
        </div>
        <!-- <div>
          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select id="status" class="validate" [formControl]='0'>
              <mat-option value=1>Livré</mat-option>
              <mat-option value=2>En développement</mat-option>
              <mat-option value=0>Choisissez une valeur</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
      <div class="row">
          <div class="input-field col s6"></div>
          <input id="picture" type="text" class="validate" formControlName="picture">
          <label for="picture">Url photo</label>

      </div>
      <div class="row">
        <div class="input-field col s4">
          <button class="btn" type="submit">
            Valider
            <i class="material-icons right">send</i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>