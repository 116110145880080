<app-navbar></app-navbar>
<h3 style="text-align: center">Projets en ligne</h3>
<div class="container">
  <div class="row">
    <div class="col s8 m6">
      <div class="card medium" *ngFor="let project of projects; let i = index">
        <div class="card-image">
          <img *ngIf="project.picture === undefined" src="assets/images/damir-bosnjak.jpg">
          <img *ngIf="project.picture !== undefined" src="{{ project.imgUrls[0] }}">
          <a href=""><span class="card-title" >{{ project.title }}</span></a>
          <a class="btn-floating halfway-fab waves-effect waves-light red"><i class="material-icons">add</i></a>
        </div>
        <div class="card-content">
          <p class="description">
            <strong>Descrition:</strong> <i> {{ project.resume }}</i>
          </p>
          <hr>
          <p class="technology">
            <strong>Technologies utilisées: </strong> <i>{{ project.technology }}</i>
          </p>
          <hr>
          <p class="lastUpdate">
            <strong>Dernière mis à jour:</strong><i>{{ project.lastUpdate }}</i>
          </p>
        </div>
        <div class="center buttons">
          <a mat-flat-button (click)="onEdit(i)">Edit</a>
          <a mat-flat-button (click)="onDelete(project)">Delete</a>
        </div>
      </div>
    </div>
    <a (click)="onAdd()">
      <fa-icon [icon]="faAddProject" transform="shrink-50 right-45"></fa-icon>
    </a>
  </div>
</div>
