import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from '../../../Services/project.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../../../models/Project.model';


@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit, AfterViewInit {

  projectForm: FormGroup;
  fileIsUploading = false;
  fileUrl: string;
  fileUploaded = false;
  editMode = false;
  titleMode = 'Ajouter un projet';

  constructor(private projectService: ProjectService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project) {
  }

  ngOnInit(): void {
    this.initForm();
    if (this.data.title !== undefined &&
      this.data.author !== undefined &&
      this.data.resume !== undefined &&
      this.data.projectId !== undefined &&
      this.data.lastUpdate !== undefined) {
      this.editMode = true;
      this.setFocus()
    }
    console.log(this.editMode)
  }

  ngAfterViewInit() {
    if (this.editMode) {
      this.setFocus();
    }
  }

  private initForm() {
    this.projectForm = this.formBuilder.group({
      title: [this.data.title !== undefined ? this.data.title : '', Validators.required],
      author: [this.data.author !== undefined ? this.data.author : '', Validators.required],
      technology: [this.data.technology !== undefined ? this.data.technology : '', Validators.required],
      resume: [this.data.resume !== undefined ? this.data.resume : '', Validators.required],
      lastUpdate: [this.data.lastUpdate !== undefined ? this.data.lastUpdate : '', Validators.required],
      status: [1, Validators.required],
      deployedUrl: [this.data.deployedUrl !== undefined ? this.data.deployedUrl : '', Validators.required],
      gitUrl: [this.data.gitUrl !== undefined ? this.data.gitUrl : '', Validators.required],
      picture: [this.data.picture !== undefined ? this.data.picture : '']
    });
  }

  onSaveProject() {
    const title = this.projectForm.get('title').value;
    const author = this.projectForm.get('author').value.toString().split(',');
    const technology = this.projectForm.get('technology').value.toString().split(',');
    const resume = this.projectForm.get('resume').value;
    const lastUpdate = this.projectForm.get('lastUpdate').value;
    const deployedUrl = this.projectForm.get('deployedUrl').value;
    const gitUrl = this.projectForm.get('gitUrl').value;
    const status = this.projectForm.get('status').value;
    const picture = this.projectForm.get('picture').value;
    const imgUrls = picture.toString().split(',');

    const newProject = new Project(
      undefined, // projectId
      title,
      author,
      technology,
      resume,
      lastUpdate,
      status,
      deployedUrl,
      gitUrl,
      imgUrls,
      picture);

    this.projectService.createNewProject(newProject);
    this.closeDialog();

  }

  // set focus on inputs for edit mode
  setFocus() {
    // this.projectForm.get('gitUrl');
    // this.projectForm.get('piture').markAsTouched();
    // this.projectForm.get('gitUrl').markAsTouched();
  }

  onUpdateProject() {
    const projectId = this.data.projectId;
    const title = this.projectForm.get('title').value;
    const author = this.projectForm.get('author').value.toString().split(',');
    const technology = this.projectForm.get('technology').value.toString().split(',');
    const resume = this.projectForm.get('resume').value;
    const lastUpdate = this.projectForm.get('lastUpdate').value;
    const deployedUrl = this.projectForm.get('deployedUrl').value;
    const gitUrl = this.projectForm.get('gitUrl').value;
    const status = this.projectForm.get('status').value;
    const picture = this.projectForm.get('picture').value;
    const imgUrls = picture.toString().split(',');

    const editProject = new Project(
      projectId, // projectId
      title,
      author,
      technology,
      resume,
      lastUpdate,
      status,
      deployedUrl,
      gitUrl,
      imgUrls,
      picture);

    this.projectService.editProject(editProject);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /*onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.projectService.uploadFile(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }*/

  /*detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }*/

}
