import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuth$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
              private http: HttpClient) {
  }

  signIn(email: string, password: string) {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(
        (_) => {
          resolve(_);
        },
        (error) => {
          reject(error);
        });
    });
  }

  logout() {
    firebase.auth().signOut();
  }
}
