<nav>
  <div class="nav-wrapper bg-primary">
    <ul class="left hide-on-med-and-down">
      <li><a routerLink="/admin/dashboard/">Dashboard</a></li>
      <li><a routerLink="/admin/me">Profil</a></li>
      <li><a routerLink="/admin/education">Studies</a></li>
      <li><a routerLink="/admin/skills">Skills</a></li>
      <li><a routerLink="/admin/employementHistory">Employement history</a></li>
      <li><a routerLink="/admin/projects">Projets</a></li>
      <li><a routerLink="/admin/settings">Settings</a></li>
    </ul>
    <ul class="right hide-on-med-and-down">
      <li ><a (click)="onSignOut()" *ngIf="isAuth" style="cursor: pointer">
        Déconnexion</a></li>
    </ul>
  </div>
</nav>
