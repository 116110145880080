<!--==========================================
                  PRE-LOADER
===========================================-->
<div id="loading">
  <div id="loading-center">
    <div id="loading-center-absolute">
      <div class="box-holder animated bounceInDown">
        <span class="load-box"><span class="box-inner"></span></span>
      </div>
      <!-- NAME & STATUS -->
      <div class="text-holder text-center animated bounceInUp">
        <h2>Andi MANIETA</h2>
      </div>
    </div>
  </div>
</div>


<!--==========================================
                    HEADER
===========================================-->
<header id="home">
  <nav id="theMenu" class="menu">

    <!--MENU-->
    <div id="menu-options" class="menu-wrap">

      <!--PERSONAL LOGO-->
      <div class="logo-flat">
        <span class="text">AM</span>
      </div>
      <br>
      <!--OPTIONS-->
      <a href="#home">
        <fa-icon [icon]="faUser"></fa-icon>
        Acceuil
      </a>
      <a href="#about">
        <fa-icon [icon]="faDashboard"></fa-icon>
        A propos de moi
      </a>
      <a href="#education">
        <fa-icon [icon]="faGraduationCap"></fa-icon>
        Etudes
      </a>
      <a href="#skills">
        <fa-icon [icon]="faSliders"></fa-icon>
        Compétences
      </a>
      <a href="#portfolios">
        <fa-icon [icon]="faArchive"></fa-icon>
        Projets
      </a>
      <a href="#interest">
        <fa-icon [icon]="faHeart"></fa-icon>
        Centre d'interêts
      </a>
      <a href="#testimonials">
        <fa-icon [icon]="faRecommandations"></fa-icon>
        Recommandations
      </a>
      <a href="#contact">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        Me contacter
      </a>
    </div>

    <!-- MENU BUTTON -->
    <div id="menuToggle">
      <div class="toggle-normal">
        <i class="material-icons top-bar">remove</i>
        <i class="material-icons middle-bar">remove</i>
        <i class="material-icons bottom-bar">remove</i>
      </div>
    </div>
  </nav>

  <div class="header-background section" style="background: url('assets/images/background.png ')">
    <div id="v-card-holder">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">

            <!-- V-CARD -->
            <div id="v-card" class="card" *ngIf="user">

              <!-- PROFILE PICTURE -->
              <div id="profile" class="right">
                <img alt="profile-image" class="img-responsive" src="../assets/images/andi.png">
                <div class="slant"></div>
                <div class="btn-floating btn-large add-btn">
                  <i class="material-icons">add</i>
                </div>
              </div>

              <div class="card-content">

                <!-- NAME & STATUS -->
                <div class="info-headings">
                  <h4 class="text-uppercase left">{{user.lastName}} {{user.firstName}}</h4>
                  <h6 class="text-capitalize left">{{user.jobTitle}}</h6>
                </div>

                <!-- CONTACT INFO -->
                <div class="infos">
                  <ul class="profile-list">
                    <li class="clearfix">
                      <span class="title"><i class="material-icons">email</i></span>
                      <span class="content"><a href="mailto:andimanieta@gmail.com">{{user.email}}</a></span>
                    </li>
                    <li class="clearfix">
                      <span class="title"><i class="material-icons">language</i></span>
                      <span class="content"><a href="andimanieta.com">andimanieta.com</a> </span>
                    </li>
                    <!--<li class="clearfix">
                      <span class="title"><fa-icon [icon]="faSkype"></fa-icon></span>
                      <span class="content">andimanieta@skype.com</span>
                    </li>-->
                    <li class="clearfix">
                      <span class="title"><i class="material-icons">phone</i></span>
                      <span class="content">{{user.phoneNumber}}</span>
                    </li>
                    <li class="clearfix">
                      <span class="title"><i class="material-icons">place</i></span>
                      <span class="content">{{user.city}}, {{user.country}}</span>
                    </li>
                  </ul>
                </div>

                <!--LINKS-->
                <div class="links">
                  <!-- TWITTER-->
                  <a href="{{user.twitter}}" id="first_one" class="social  btn-floating blue">
                    <fa-icon [icon]="faTwitter"></fa-icon>
                  </a>
                  <!-- GOOGLE+-->
                  <a href="#" class="social  btn-floating red">
                    <fa-icon [icon]="faGoogle"></fa-icon>
                  </a>
                  <!-- LINKEDIN-->
                  <a href="{{user.linkedin}}" target="blank" class="social  btn-floating blue darken-3">
                    <fa-icon [icon]="faLinkedin"></fa-icon>
                  </a>
                  <!-- RSS-->
                  <a href="#" class="social  btn-floating orange darken-3">
                    <fa-icon [icon]="faLinkedin"></fa-icon>
                  </a>
                  <!-- GITLAB-->
                  <a href="{{user.gitRepo}}" target="blank" alt="Mes sources" class="social btn-floating indigo">
                    <i class="material-icons">code</i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!--==========================================
                   ABOUT
===========================================-->
<div id="about" class="section" *ngIf="user">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- DETAILS -->
        <div id="about-card" class="card">
          <div class="card-content">
            <!-- ABOUT PARAGRAPH -->

            <p>
              {{user.description}}

            </p>
          </div>

          <!-- BUTTONS -->
          <div id="about-btn" class="card-action">
            <div class="about-btn">
              <!-- DOWNLOAD CV BUTTON -->
              <a href="../../assets/documents/CV_Andi_MANIETA.pdf" target="blank" class="btn waves-effect">Voir mon
                CV</a>
              <!-- CONTACT BUTTON -->
              <a href="#contact" class="btn waves-effect">Me contacter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--==========================================
                   ETUDES
===========================================-->
<section id="education" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faGraduationCap"></fa-icon>
        Etudes
      </h4>
    </div>

    <div id="timeline-education">

      <!-- FIRST TIMELINE -->
      <div class="timeline-block">
        <!-- DOT -->
        <div class="timeline-dot">
          <h6>P</h6>
        </div>
        <!-- TIMELINE CONTENT -->
        <div class="card timeline-content">
          <div class="card-content">
            <!-- TIMELINE TITLE -->
            <h6 class="timeline-title">Bach - Developeur applications web/mobile</h6>
            <!-- TIMELINE TITLE INFO -->
            <div class="timeline-info">
              <h6>
                <small>ETNA (Ecole des téchnologies numériques avancées)</small>
              </h6>
              <h6>
                <small>Oct 2020 - Oct 2021</small>
              </h6>
            </div>
            <!-- TIMELINE PARAGRAPH -->
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est natus saepe unde eos autem suscipit cum
              distinctio. Quas alias repudiandae harum quam molestias perspiciatis voluptates, esse, eius illo non
              perferendis.
            </p>
            <!-- BUTTON TRIGGER MODAL -->
            <a href="#" class="modal-dot" data-toggle="modal" data-target="#myModal-1">
              <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- SECOND TIMELINE -->
      <div class="timeline-block">
        <!-- DOT -->
        <div class="timeline-dot">
          <h6>H</h6>
        </div>
        <!-- TIMELINE CONTENT -->
        <div class="card timeline-content">
          <div class="card-content">
            <!-- TIMELINE TITLE -->
            <h6 class="timeline-title">Licence - Mathématiques & Informatique</h6>
            <!-- TIMELINE TITLE INFO -->
            <div class="timeline-info">
              <h6>
                <small>Université de Strasbourg</small>
              </h6>
              <h6>
                <small>Jan 2016 - Mar 2020</small>
              </h6>
            </div>
            <!-- TIMELINE PARAGRAPH -->
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam, doloribus enim blanditiis dolorum quasi
              aspernatur quidem asperiores fugiat assumenda iste explicabo et architecto velit fugit possimus ut
              quibusdam consequatur recusandae?

            </p>
          </div>
        </div>
      </div>

      <!-- THIRD TIMELINE -->
      <div class="timeline-block">
        <!-- DOT -->
        <div class="timeline-dot">
          <h6>C</h6>
        </div>
        <!-- TIMELINE CONTENT -->
        <div class="card timeline-content">
          <div class="card-content">
            <!-- TIMELINE TITLE -->
            <h6 class="timeline-title">Bac Scientifique</h6>
            <!-- TIMELINE TITLE INFO -->
            <div class="timeline-info">
              <h6>
                <small>Lycée Gianelli</small>
              </h6>
              <h6>
                <small>Sept 2013</small>
              </h6>
            </div>
            <!-- TIMELINE PARAGRAPH -->
            <p>
              <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur consequuntur culpa dolore
                dolores excepturi illo, minus nihil nobis odit officia quos repellendus vitae, voluptatibus. Amet eius
                minima pariatur rem reprehenderit!</span>
              <span>Asperiores consequuntur dolorum ea excepturi, molestiae non reiciendis tempore. Accusantium ad
                asperiores aspernatur autem doloribus et explicabo quae ratione soluta. Assumenda at dolore, dolorum
                iste neque nostrum quae quia velit.</span>
            </p>

          </div>
        </div>
      </div>


    </div>
  </div>
</section>


<!--==========================================
                   SKILLS
===========================================-->
<section id="skills" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faSliders"></fa-icon>
        Compétences
      </h4>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div id="skills-card" class="card">
          <div class="card-content">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- FIRST SKILL SECTION -->
                <div class="skills-title">
                  <h6 class="text-center">Langages</h6>
                </div>
                <!-- FIRST SKILL BAR -->
                <div class="skillbar" data-percent="90%">
                  <div class="skillbar-title"><span>HTML5</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">90%</div>
                </div>
                <!-- SECOND SKILL BAR  -->
                <div class="skillbar" data-percent="90%">
                  <div class="skillbar-title"><span>CSS3</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">80%</div>
                </div>
                <!-- THIRD SKILL BAR  -->
                <div class="skillbar" data-percent="80%">
                  <div class="skillbar-title"><span>SASS</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">80%</div>
                </div>
                <!-- FOURTH SKILL BAR  -->
                <div class="skillbar" data-percent="85%">
                  <div class="skillbar-title"><span>Javascript</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">85%</div>
                </div>
                <!-- FITH SKILL BAR  -->
                <div class="skillbar" data-percent="70%">
                  <div class="skillbar-title"><span>PHP</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">70%</div>
                </div>
                <!-- FITH SKILL BAR  -->
                <div class="skillbar" data-percent="75%">
                  <div class="skillbar-title"><span>JAVA</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">75%</div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="skills-title">
                  <h6 class="text-center">Frameworks</h6>
                </div>
                <!-- FIFE SKILL BAR  -->
                <div class="skillbar" data-percent="85%">
                  <div class="skillbar-title"><span>Angular</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">85%</div>
                </div>
                <!-- SEVENTH SKILL BAR  -->
                <div class="skillbar" data-percent="75%">
                  <div class="skillbar-title"><span>NodeJS</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">75%</div>
                </div>
                <!-- EIGTH SKILL BAR  -->
                <div class="skillbar" data-percent="70%">
                  <div class="skillbar-title"><span>ExpressJS</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">70%</div>
                </div>
                <!-- EIGTH SKILL BAR  -->
                <div class="skillbar" data-percent="82%">
                  <div class="skillbar-title"><span>MongoDB</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">82%</div>
                </div>
                <!-- EIGTH SKILL BAR  -->
                <div class="skillbar" data-percent="58%">
                  <div class="skillbar-title"><span>Symfony</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">58%</div>
                </div>
                <!-- EIGTH SKILL BAR  -->
                <div class="skillbar" data-percent="78%">
                  <div class="skillbar-title"><span>Bootstrap & Materialize</span>
                  </div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">78%</div>
                </div>
              </div>


              <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- SECOND SKILL SECTION -->
                <div class="skills-title">
                  <h6 class="text-center">Software & Workflow</h6>
                </div>
                <!-- FIRST SKILL BAR -->
                <div class="skillbar" data-percent="80%">
                  <div class="skillbar-title"><span>IntelliJ</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">80%</div>
                </div>
                <!-- SECOND SKILL BAR  -->
                <div class="skillbar" data-percent="90%">
                  <div class="skillbar-title"><span>VS Code</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">90%</div>
                </div>
                <!-- THIRD SKILL BAR  -->
                <div class="skillbar" data-percent="90%">
                  <div class="skillbar-title"><span>Dev Console</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">90%</div>
                </div>
                <!-- FOURTH SKILL BAR  -->
                <div class="skillbar" data-percent="85%">
                  <div class="skillbar-title"><span>Git</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">85%</div>
                </div>
              </div>

              <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- FIRST SKILL BAR -->
                <div class="skills-title">
                  <h6 class="text-center">Compétences personnels</h6>
                </div>
                <div class="skillbar" data-percent="85%">
                  <div class="skillbar-title"><span>Autonomie</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">85%</div>
                </div>
                <!-- SECOND SKILL BAR  -->
                <div class="skillbar" data-percent="75%">
                  <div class="skillbar-title"><span>Créativité</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">75%</div>
                </div>
                <!-- THIRD SKILL BAR  -->
                <div class="skillbar" data-percent="70%">
                  <div class="skillbar-title"><span>Travail d'équipe</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">70%</div>
                </div>
                <!-- FOURTH SKILL BAR  -->
                <div class="skillbar" data-percent="65%">
                  <div class="skillbar-title"><span>Gestion de projet</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">65%</div>
                </div>
              </div>


              <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- THIRD SKILL SECTION -->
                <div class="skills-title">
                  <h6 class="text-center">Savoir-être</h6>
                </div>
                <!-- FIRST SKILL BAR -->
                <div class="skillbar" data-percent="80%">
                  <div class="skillbar-title"><span>Esprit d'analyse</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">80%</div>
                </div>
                <!-- SECOND SKILL BAR  -->
                <div class="skillbar" data-percent="90%">
                  <div class="skillbar-title"><span>Sens de l'écoute</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">90%</div>
                </div>
                <!-- THIRD SKILL BAR  -->
                <div class="skillbar" data-percent="80%">
                  <div class="skillbar-title"><span>Curieux</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">80%</div>
                </div>
                <!-- FOURTH SKILL BAR  -->
                <div class="skillbar" data-percent="75%">
                  <div class="skillbar-title"><span>Dynamique</span></div>
                  <div class="skillbar-bar"></div>
                  <div class="skill-bar-percent">75%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--==========================================
                   EXPERIENCE
===========================================-->
<section id="experience" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faExperience"></fa-icon>
        Experience
      </h4>
    </div>

    <div id="timeline-experience">

      <!-- FIRST TIMELINE -->
      <div class="timeline-block">
        <!-- DOT -->
        <div class="timeline-dot">
          <h6>D</h6>
        </div>
        <!-- TIMELINE CONTENT -->
        <div class="card timeline-content">
          <div class="card-content">
            <!-- TIMELINE TITLE -->
            <h6 class="timeline-title">Opérateur Technique</h6>
            <!-- TIMELINE TITLE INFO -->
            <div class="timeline-info">
              <h6>
                <small>Canon Business Services</small>
              </h6>
              <h6>
                <small>Février 2020 - Septembre 2020</small>
              </h6>
            </div>
            <!-- TIMELINE PARAGRAPH -->
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error nemo numquam perferendis quam quasi
              quibusdam sit voluptates! Architecto deserunt facere laboriosam magnam pariatur quis similique voluptates!
              Aliquid non perferendis sunt?
            </p>
            <!-- BUTTON TRIGGER MODAL -->
            <a href="#" class="modal-dot" data-toggle="modal" data-target="#myModal-4">
              <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- SECOND TIMELINE -->
      <div class="timeline-block">
        <!-- DOT -->
        <div class="timeline-dot">
          <h6>F</h6>
        </div>
        <!-- TIMELINE CONTENT -->
        <div class="card timeline-content">
          <div class="card-content">
            <!-- TIMELINE TITLE -->
            <h6 class="timeline-title">Agent d'éxploitation Informatique</h6>
            <!-- TIMELINE TITLE INFO -->
            <div class="timeline-info">
              <h6>
                <small>Euro Information production</small>
              </h6>
              <h6>
                <small>Juillet 2019 - Août 2019</small>
              </h6>
            </div>
            <!-- TIMELINE PARAGRAPH -->
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi autem cumque deleniti dolores dolorum
              ducimus eaque eum, in labore, laborum officiis, praesentium quibusdam suscipit ullam voluptates? Cumque
              impedit quasi quisquam.

            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--==========================================
                  MODALS
===========================================-->

<!--MODAL ONE-->
<div class="modal fade" id="myModal-1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <!--MODAL HEADER-->
      <div class="modal-header  text-center">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel-1">Developeur d'applications web/mobiles </h4>
        <h6>
          <small>Alternance</small>
          <small>Oct 2020 - Oct 2021</small>
        </h6>
      </div>
      <!--MODAL BODY-->
      <div class="modal-body">
        <p>
          <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus at blanditiis dicta, dolore
            doloremque eligendi excepturi facilis iure iusto laudantium nesciunt non quia rem repellat, repellendus
            velit veniam voluptas voluptatem.</span>
          <span>Aliquid amet architecto commodi hic laborum magni odit praesentium unde, veniam voluptates. Asperiores
            dicta facilis sed soluta. Blanditiis consequuntur eius facere nisi numquam optio quasi saepe similique, sint
            sit unde!</span>
        </p>
      </div>
      <!--MODAL FOOTER-->
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!--MODAL TWO-->
<div class="modal fade" id="myModal-2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel-2">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <!--MODAL HEADER-->
      <div class="modal-header  text-center">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel-2">EDUCATION AT Y</h4>
        <h6>
          <small>Jan 2014 - Mar 2015</small>
        </h6>
      </div>
      <!--MODAL BODY-->
      <div class="modal-body">
        <p>
          <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consequuntur distinctio eaque error
            facere molestiae molestias necessitatibus nisi nobis omnis, perferendis quas quidem similique, suscipit
            temporibus? A earum nihil sapiente.</span>
          <span>Accusantium aliquid aut blanditiis culpa doloribus ducimus earum eum fuga iure magnam minus molestias,
            necessitatibus nobis obcaecati omnis optio, quaerat quam qui quia quisquam recusandae rem totam ullam
            voluptatem voluptatibus.</span>
        </p>
      </div>
      <!--MODAL FOOTER-->
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!--==========================================
             PROJECTS
===========================================-->
<section id="projects" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faProject"></fa-icon>
        Projets
      </h4>
    </div>
    <div id="projects-card" class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card" *ngFor="let project of projects; let i = index">
          <div [ngClass]="(i % 2) === 0 ? 'projects odd' : 'projects even'">
            <!--IMAGE-->
            <div class="image">
              <img *ngIf="!project.picture" alt="projects-image" src="../assets/images/blog.png" />
              <img *ngIf="project.picture" alt="projects-image" src="{{project.imgUrls[0]}}" />
              <div class="image-overlay"></div>
            </div>
            <!--DETAILS-->
            <div class="content">
              <ol class="breadcrumb">
                <li><a href="#"><h4>{{project.title}}</h4></a></li>
                <!-- <li><a href="#">Design</a></li> -->
                <!-- <li class="active">Material</li> -->
              </ol>
              <h6>{{project.title}}</h6>
              <p>
                {{project.resume}}</p>
              <hr>
              <h6>Technologies :</h6>
              <i *ngFor="let technology of project.technology">
                {{technology}}, &nbsp;</i>
              <hr>
              <a href="{{project.deployedUrl}}"> Lien site </a>
            </div>
          </div>
        </div>
        <br><br>
      </div>
    </div>
  </div>
</section>

<!--==========================================
                   INTEREST
===========================================-->
<section id="interest" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faHeart"></fa-icon>
        Centres d'intérêt
      </h4>
    </div>

    <div id="interest-card" class="card">
      <!--INTEREST TEXT-->
      <div class="card-content">
        <p>
          <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias ea eos et ex illo in laboriosam
            laudantium, modi natus neque obcaecati qui quis ratione similique veniam. Esse nulla sequi
            voluptatem.</span><span>Accusamus culpa distinctio earum est, eveniet facere, laborum maxime modi, molestiae
            officia quas quo veniam voluptas. Facilis fugiat laboriosam libero magnam, maiores nihil placeat quos
            sapiente sed soluta voluptas voluptate?</span>
        </p>
      </div>

      <!--INTEREST ICONS-->
      <div class="row no-gutters">

        <!--INTEREST ICON ONE-->
        <div class="col-md-2 col-sm-4 col-xs-6  box text-center">
          <div class="interest-icon">
            <fa-icon [icon]="faMusic"></fa-icon>
            <span>Musique</span>
          </div>
        </div>
        <!--INTEREST ICON TWO-->
        <div class="col-md-2 col-sm-4 col-xs-6 box text-center">
          <div class="interest-icon-even">
            <fa-icon [icon]="faGaming"></fa-icon>
            <span>Jeux vidéo</span>
          </div>
        </div>
        <!--INTEREST ICON THREE-->
        <div class="col-md-2 col-sm-4 col-xs-6 box text-center">
          <div class="interest-icon">
            <fa-icon [icon]="faSocietyGames"></fa-icon>
            <span>Jeux de societé</span>
          </div>
        </div>
        <!--INTEREST ICON FOUR-->
        <div class="col-md-2 col-sm-4 col-xs-6 box text-center">
          <div class="interest-icon-even">
            <fa-icon [icon]="faFootball"></fa-icon>
            <span>Football</span>
          </div>
        </div>
        <!--INTEREST ICON FIVE-->
        <div class="col-md-2 col-sm-4 col-xs-6 box text-center">
          <div class="interest-icon">
            <fa-icon [icon]="faTraveling"></fa-icon>
            <span>Voyages</span>
          </div>
        </div>
        <!--INTEREST ICON SIX-->
        <div class="col-md-2 col-sm-4 col-xs-6 box text-center">
          <div class="interest-icon-even">
            <fa-icon [icon]="faMovie"></fa-icon>
            <span>Cinéma</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


<!--==========================================
                 RECOMMANDATIONS
===========================================-->
<section id="testimonials" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <fa-icon [icon]="faRecommandations"></fa-icon>
        Recommandations
      </h4>
    </div>
    <div id="testimonials-card" class="row card">
      <div class="col-md-12 col-xs-12">
        <!-- SLIDER-->
        <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="false">
          <!-- INDICATORS -->
          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
          </ol>
          <!-- WRAPPER FOR SLIDES -->
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <div class="col-md-12">
                <!--CLIENT IMAGE-->
                <div class="ben center-block">
                  <img alt="client-image" class="center-block" src="../assets/images/client.png">
                </div>
                <!--CLIENT TEXT-->
                <blockquote style="">
                  <p>
                    Je soussigné Noura Ammari, responsable d'équipe au sein de la société Canon, certifie que Monsieur
                    Andi MANIETA a bien été employé au sein de nos services du 13/02/2020 au 25/09/2020.
                    ...
                  </p>
                  <p>
                    <!-- Curieux, responsable, très impliqué et naturellement doté d’un esprit critique et de synthèse, il est
                     un moteur qui aime les challenges et s’investir dans de nouveaux projets. Doté d’un fort esprit
                     d’équipe, il a également toujours su travailler en parfaite autonomie et responsabilité tout au long
                     de ses missions.
                     </p>
                     <p>
                     Pour toutes ces raisons, nous vous recommandons vivement la candidature de Andi MANIETA au sein de vos
                     équipes. Nous sommes convaincus qu’il vous apportera, par son expérience professionnelle et sa force
                     de caractère, une réelle valeur ajoutée et nous lui souhaitons beaucoup de réussite dans sa carrière
                     professionnelle.-->
                  </p>
                  <cite>Noura AMMARI, Responsable d'équipe Canon</cite>
                </blockquote>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>
</section>


<!--==========================================
                  CONTACT
===========================================-->
<section id="contact" class="section">
  <div class="container">
    <!-- SECTION TITLE -->
    <div class="section-title">
      <h4 class="text-uppercase text-center">Contact</h4>
    </div>
    <div class="row">
      <div id="contact-card" class="col-md-12 col-sm-12 col-xs-12">
        <!-- CONTACT FORM -->
        <div class="card">
          <div class="card-content">
            <form [formGroup]="contactForm" id="contact-form" (ngSubmit)="onSubmit()" >
              <!-- NAME -->
              <div class="input-field">
                <input id="first_name"  maxlength="50" type="text" class="validate" name="first_name" 
                formControlName="senderName" required>
                <label for="first_name">Nom</label>
              </div>
              <!--SUBJECT-->
              <div class="input-field">
                <input id="sub" maxlength="50" type="text" class="validate" name="sub" 
                formControlName="subject"> 
                <label for="sub">Objet</label>
              </div>
              <!--EMAIL-->
              <div class="input-field">
                <input id="email"  maxlength="40" type="email" class="validate" name="email" 
                formControlName="senderEmail" required>
                <label for="email">Email</label>
              </div>
              <!--MESSAGE-->
              <div class="input-field">
                <textarea id="textarea1" class="materialize-textarea" name="message" 
                formControlName="content" required></textarea>
                <label for="textarea1">Message</label>
              </div>
              <!-- SEND BUTTON -->
              <div class="contact-send">
                <button id="submit" name="contactSubmit" type="submit" value="Submit"
                  class="btn waves-effect" [disabled]="!contactForm.valid">Envoyer</button>
              </div>
            </form>
          </div>
          <!--          &lt;!&ndash;FORM LOADER&ndash;&gt;-->
          <!--          <div id="form-loader" class="progress is-hidden">-->
          <!--            <div class="indeterminate"></div>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="col-md-5 col-sm-12 col-xs-12">
        <div class="container">
          <fa-icon size="lg"></fa-icon>
        </div>
      </div>

    </div>
  </div>
</section>

<!--SCROLL TO TOP-->
<div id="scroll-top">
  <div id="scrollup">
    <fa-icon [icon]="faAngleUp"></fa-icon>
  </div>
</div>

<!--==========================================
                      FOOTER
===========================================-->

<footer>
  <div class="container">
    <!--FOOTER DETAILS-->
    <p class="text-center">
      © Andi MANIETA
    </p>
  </div>
</footer>