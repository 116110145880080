export class Project{

  constructor(
              public projectId: string,
              public title: string,
              public author: Array<string>,
              public technology: Array<string>,
              public resume: string,
              public lastUpdate: Date,
              public status?: number,
              public deployedUrl?: string,
              public gitUrl?: string,
              public imgUrls?: Array<string>,
              public picture?: string) {
  }
}
