import { Injectable } from '@angular/core';
import { Education } from '../models/Education.model';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor() { }

  createNewProgram(program: Education){
    
  }
}
