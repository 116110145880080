<app-navbar></app-navbar>
<div class="row" *ngIf="me" >
  <div class="col-md-4">
    <div class="card card-user">
      <div class="image">
        <img src="../../assets/images/background.png" alt="moi">
      </div>
      <div class="card-body">
        <div class="author">
          <a href="#">
            <img class="avatar border-gray" src="assets/images/andi.png" alt="moi">
            <h5 class="title">{{me.lastName}} {{me.firstName}}</h5>
          </a>
          <p class="description">
            @{{me.lastName | lowercase}}{{me.firstName | lowercase}} <br>
            <strong>{{me.company}}</strong>
          </p>
        </div>
        <p class="description text-center">
          <span style="font-size: smaller;">{{me.description}}</span>
        </p>
      </div>
      <div class="card-footer">
        <hr>
        <div class="button-container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6 ml-auto">
              <h5>xx
                <br>
                <small>Visiteurs</small>
              </h5>
            </div>
            <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
              <h5>xx
                <br>
                <small>Contact</small>
              </h5>
            </div>
            <div class="col-lg-3 mr-auto">
              <h5>xx
                <br>
                <small>autres</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="card card-user">
      <div class="card-header">
        <h5 class="card-title">Edit Profile</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-5 pr-1">
              <div class="form-group">
                <label>Company </label>
                <input type="text" class="form-control" value="{{me.company}}" formControlName="company">
              </div>
            </div>
            <div class="col-md-3 px-1">
              <div class="form-group">
                <label>Username</label>
                <input type="text" disabled class="form-control"
                  value="{{me.lastName | lowercase }}{{me.firstName | lowercase}}">
              </div>
            </div>
            <div class="col-md-4 pl-1">
              <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control" formControlName="email">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 pr-1">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" formControlName="firstName">
              </div>
            </div>
            <div class="col-md-5 pl-1">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="" formControlName="lastName">
              </div>
            </div>
            <div class="col-md-2 pl-1">
              <div class="form-group">
                <label>Age</label>
                <input type="number" class="form-control" formControlName="age">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Job</label>
                <input type="text" class="form-control" formControlName="jobTitle">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 pl-1">
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" formControlName="phoneNumber">
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" formControlName="city">
              </div>
            </div>
            <div class="col-md-2 px-1">
              <div class="form-group">
                <label>Country</label>
                <input type="text" class="form-control" formControlName="country">
              </div>
            </div>
            
            <div class="col-md-2 pl-1">
              <div class="form-group">
                <label>Postal Code</label>
                <input type="number" class="form-control" formControlName="zip">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>About Me</label>
                <textarea rows="40" cols="10" style="height: 400px; padding: 2%;" class="form-control textarea" formControlName="description">{{me.description}}
                </textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button type="submit" [disabled]="userForm.invalid" class="btn btn-primary btn-round">Update
                Profile</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>