import { Component } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Andi MANIETA - Portfolio';
   constructor() {

    const app = firebase.initializeApp(environment.firebase);
   }
}
