<div class="main_container">
  <mat-toolbar color="primary" class="mat-elevation-z10">
    <span class="fill-remaining-space"><p>Connexion (admin)</p></span>
  </mat-toolbar>
  <div class="form_container">

    <form [formGroup]="signInForm" class="form">

      <mat-form-field class="form-element">
        <input matInput placeholder="Email" formControlName="email">
        <mat-error *ngIf="!email.valid && email.touched">{{ titleAlert }}</mat-error>
      </mat-form-field>

      <mat-form-field class="form-element">
        <input matInput type="password" placeholder="Password" formControlName="password">
        <mat-error *ngIf="!signInForm.controls['password'].valid && signInForm.controls['password'].touched">
          {{ getErrorPassword() }}
        </mat-error>
      </mat-form-field>

      <div class="form-element">
        <button mat-raised-button color="accent" class="button" (click)="onSubmit()"
                [disabled]="!signInForm.valid">Sign In</button>
        <mat-error *ngIf="serverErreur">{{ serverErreur }}</mat-error>
      </div>

    </form>
  </div>

</div>

