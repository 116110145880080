import { CommonModule } from '@angular/common';
import { Injectable } from '@angular/core';
import { Project } from '../models/Project.model';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';
import { map } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projects: Project[] = [];
  project$ = new Subject<Project[]>();

  constructor(private afs: AngularFirestore) { }

  createNewProject(newProject: Project) {
    this.projects.push(newProject);
    this.saveProject(newProject);
    this.emitProjects();
  }

  emitProjects() {
    this.project$.next(this.projects);
  }

  saveProject(project: Project) {
    const projectRef = this.afs.collection('projects');
    let projectObj = Object.assign({}, project);
    delete projectObj.projectId;
    projectRef.add(projectObj).then(a => {
      console.log(a)
    }).then(_ => {
      console.log('Saved successfully')
    }).catch(e => {
      console.log('Error : ', e)
    });
  }

  editProject(project: Project) {
    const projectRef = this.afs.collection('projects').doc(project.projectId);
    const projectObj = Object.assign({}, project);
    projectRef.update(projectObj);
  }

  getProjects() {
    this.afs.collection('projects').snapshotChanges().subscribe(docs => {
      this.projects = [];
      docs.forEach(doc => {
        const projectId = doc.payload.doc.id;
        const dataValue = doc.payload.doc.data() as Project;
        dataValue.projectId = projectId;
        this.projects.push(dataValue as Project);
        console.log(this.projects)
      });
      this.emitProjects();

    });
  }

  getOneProject(id: number) {
    
    return new Promise(
      (resolve, reject) => {
        firebase.database().ref('/projects/' + id)
          .once('value').then(
            (data) => {
              resolve(data.val());
            },
            (error) => {
              reject(error);
            }
          )
      }
    )
  }

  removeProject(project: Project) {
    const projectRef = this.afs.collection('projects').doc(project.projectId);
    projectRef.delete().then(_ => {
      console.log('Delete successful !')
    }).catch(e => {
      console.log('Error : ', e)
    })

    const index = this.projects.findIndex(p => p.projectId === project.projectId);
    if (index > -1) {
      this.projects = this.projects.splice(index, 1);
    }
    this.emitProjects();
  }

  /* uploadFile(file: File) {
     return new Promise(
       (resolve, reject) => {
         const almostUniqueFileName = Date.now().toString();
         console.log(almostUniqueFileName);
         const upload = firebase.storage().ref()
           .child('images/' + almostUniqueFileName + file.name)
           .put(file);
         upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
           () => {
             console.log('Chargement en cours...');
           },
           (error) => {
             console.log('Erreufr de chargement : ' + error);
             reject();
           },
           () => {
             resolve(upload.snapshot.ref.getDownloadURL());
           }
         );
       }
     );
   }*/

}
