import { Project } from './../models/Project.model';
import { MaterialModule } from './../material.module';
import { BackOfficeRoutingModule } from './back-office.routing';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { NavbarComponent } from '../navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from '../Services/auth.service';
import { ProjectService } from '../Services/project.service';
import { AuthGuardService } from '../Services/auth-guard.service';
import { environment } from 'src/environments/environment';
import { MeComponent } from './me/me.component';
import { HomeComponent } from '../home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { SigninComponent } from '../auth/signin/signin.component';
import { EmployementHistoryComponent } from './employement-history/employement-history.component';
import { SkillsComponent } from './skills/skills.component';
import { EditProjectComponent } from './projects/edit-project/edit-project.component';
import { EditEducationComponent } from './education/edit-education/edit-education.component';
import { SettingsComponent } from './settings/settings.component';
import { EducationComponent } from './education/education.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/share.module';
import { AngularFireFunctionsModule } from "@angular/fire/functions";



@NgModule({
  declarations: [
    NavbarComponent,
    MeComponent,
    HomeComponent,
    ProjectsComponent,
    NotFoundComponent,
    NavbarComponent,
    SigninComponent,
    EditProjectComponent,
    EmployementHistoryComponent,
    SkillsComponent,
    EducationComponent,
    EditEducationComponent,
    SettingsComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BackOfficeRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    SharedModule,
    MaterialModule
  ],

  exports: [
    NavbarComponent,
    MeComponent,
    HomeComponent,
    ProjectsComponent,
    NotFoundComponent,
    NavbarComponent,
    SigninComponent,
    EditProjectComponent,
    EmployementHistoryComponent,
    SkillsComponent,
    EducationComponent,
    EditEducationComponent,
    SettingsComponent,
    DashboardComponent
  ],

  providers: [
    AuthService,
    ProjectService,
    AuthGuardService
  ],
})
export class BackOfficeModule {
}
