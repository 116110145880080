import { DashboardComponent } from './dashboard/dashboard.component';
import { EducationComponent } from './education/education.component';
import { EmployementHistoryComponent } from '../back-office/employement-history/employement-history.component';
import { SkillsComponent } from '../back-office/skills/skills.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from '../auth/signin/signin.component';
import { EditProjectComponent } from '../back-office/projects/edit-project/edit-project.component';
import { MeComponent } from '../back-office/me/me.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { ProjectsComponent } from '../back-office/projects/projects.component';
import { AuthGuardService } from '../Services/auth-guard.service';
import { EditEducationComponent } from '../back-office/education/edit-education/edit-education.component';
import { SettingsComponent } from './settings/settings.component';


const backOfficeRoutes: Routes = [
    {
      path: 'admin',
      children: [
        {path: '', redirectTo: 'me', pathMatch: 'full'},
        {path: 'login', component: SigninComponent},
        {path: 'dashboard', component: DashboardComponent},
        {path: 'editProject',  canActivate: [AuthGuardService], component: EditProjectComponent},
        {path: 'editFormation',  canActivate: [AuthGuardService], component: EditEducationComponent},
        {path: 'editSkill',  canActivate: [AuthGuardService], component: EditProjectComponent},
        {path: 'editEmploHistory',  canActivate: [AuthGuardService], component: EditProjectComponent},
        {path: 'me',  canActivate: [AuthGuardService], component: MeComponent},
        {path: 'projects',  canActivate: [AuthGuardService], component: ProjectsComponent},
        {path: 'education',  canActivate: [AuthGuardService], component: EducationComponent},
        {path: 'skills',  canActivate: [AuthGuardService], component: SkillsComponent},
        {path: 'employementHistory',  canActivate: [AuthGuardService], component: EmployementHistoryComponent},
        {path: 'settings',  canActivate: [AuthGuardService], component: SettingsComponent},
      ]
    },
    {path: '**', component: NotFoundComponent}
  ];

@NgModule({
    imports: [
        RouterModule.forRoot(backOfficeRoutes)
    ],
    exports: [
      RouterModule
    ]
})

export class BackOfficeRoutingModule{}