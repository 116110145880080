import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService } from './Services/auth.service';
import { ProjectService } from './Services/project.service';
import { AuthGuardService } from './Services/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { BackOfficeModule } from './back-office/back-office.module';
import { SharedModule } from './shared/share.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BackOfficeModule
  ],
  providers: [
    AuthService,
    ProjectService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
