import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';
import { User } from '../models/User.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;
  user$ = new Subject<User>();


  constructor(private afs: AngularFirestore) { }

  loadUser() {
    this.afs
      .collection('me')
      .snapshotChanges()
      .map(doc => {
        return {
          uid: doc[0].payload.doc.id,
          ...doc[0].payload.doc.data() as User
        };
      })
      .subscribe((me: User) => {
        this.user = me;
        this.user$.next(this.user);
      });
  }

  updateUser(user: User) {
    const docId = user.uid;
    console.log(docId)
    this.afs
      .collection('me')
      .doc(user.uid)
      .update(user).then(_ => {
        console.log('Datas updated successfully !!!');
      }).catch(e => {
        console.log('Error : ' + e);
      });
  }
}
